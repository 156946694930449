@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  * {
    @apply font-inter;
  }

  video::-webkit-media-controls {
    @apply hidden;
  }

  .modal-height {
    max-height: calc(100vh - 80px);
  }

  .btn-animated {
    @apply transition-all duration-300 active:focus:scale-95;
  }

  @font-face {
    font-family: "GT Eesti Pro Display";
    font-weight: 100;
    src: url("../public/fonts/GT-Eesti-Display-Thin.otf");
  }

  @font-face {
    font-family: "GT Eesti Pro Display";
    font-weight: 200;
    src: url("../public/fonts/GT-Eesti-Display-UltraLight.otf");
  }

  @font-face {
    font-family: "GT Eesti Pro Display";
    font-weight: 300;
    src: url("../public/fonts/GT-Eesti-Display-Light.otf");
  }

  @font-face {
    font-family: "GT Eesti Pro Display";
    font-weight: 400;
    src: url("../public/fonts/GT-Eesti-Display-Regular.otf");
  }

  @font-face {
    font-family: "GT Eesti Pro Display";
    font-weight: 500;
    src: url("../public/fonts/GT-Eesti-Display-Medium.otf");
  }

  @font-face {
    font-family: "GT Eesti Pro Display";
    font-weight: 600;
    src: url("../public/fonts/GT-Eesti-Display-Bold.otf");
  }

  @font-face {
    font-family: "GT Eesti Pro Display";
    font-weight: 700;
    src: url("../public/fonts/GT-Eesti-Display-UltraBold.otf");
  }
}

@layer components {
  .text-shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .text-shadow-md {
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-lg {
    text-shadow: 0 15px 30px rgba(0, 0, 0, 0.11), 0 5px 15px rgba(0, 0, 0, 0.08);
  }

  .text-shadow-none {
    text-shadow: none;
  }
}
